<template>
  <div class="w-full flex border-b border-dashed border-grey-darker-200">
    <div class="w-2/6 border-r border-grey-darker-200 py-5">
      <span class="font-sansDemi base-font-gray-200 text-xl leading-none"> {{ title }} </span>
    </div>
    <div class="w-4/6">
      <input 
        v-model="value" 
        :type="type"
        class="base-font-gray-200 text-xl leading-none outline-none bucket-input-shadow py-5 pl-8 w-full"
        :placeholder="placeholder"
        @input="sendData"
        :required="required"
      >
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {},
      valueKey: {},
      placeholder: {
        type: String,
        default: '*************'
      },
      required: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: 'text'
      }
    },
    data(){
      return{
        value: ''
      }
    },
    methods: {
      sendData(){
        this.$emit('accountData', {
          [this.valueKey]: this.value
        })
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>