<template>
  <div class="mb-60">
    <SettingsTitle title="Account Details"/>
    <AccountLine title="Name" :value="user_meta.name" />
    <AccountLine title="Email" :value="user_meta.email" />

    <SettingsTitle title="Change Password" class="mt-14"/>
    <AccountLineInput title="Type current password" valueKey="currentPassword" @accountData="setPasswordData" />
    <AccountLineInput title="New password" valueKey="newPassword" @accountData="setPasswordData" />
    <AccountLineInput title="Repeat new password" valueKey="newPasswordConfirm" @accountData="setPasswordData" />
    <div class="w-full flex">
      <div class="w-2/6 "></div>
      <div class="w-4/6 py-5 pl-8">
        <button 
          @click="changePassword"
          class="border-grey-darker-100 border rounded-3xl px-2 py-1 text-sm font-mono font-medium"
        >
          Change Password
        </button>
      </div>
    </div>
    

    <SettingsTitle title="User Type" class="mt-14"/>
    <AccountLine title="Role" :value="userAppData.role ? userAppData.role : ' '" />
    <AccountLine title="Organization type" :value="userAppData.organization ? userAppData.organization : ' '" />
  </div>
</template>

<script>
  import SettingsTitle from '@/components/settings/SettingsTitle'
  import AccountLine from '@/components/settings/AccountLine'
  import AccountLineInput from '@/components/settings/AccountLineInput'
  import { mapGetters } from 'vuex'

  export default {
    components: { 
      SettingsTitle, 
      AccountLine, 
      AccountLineInput 
    },
    data(){
      return{
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        userRole: ''
      }
    },
    computed:{
      ...mapGetters({
        user_meta: 'general/user_meta',
        userAppData: 'general/userAppData'
      })
    },
    methods: {
      setPasswordData(data){
        const keyName = Object.keys(data)[0]
        this[keyName] = data[keyName]
      },
      changePassword(){

      }
    },
    mounted(){

    }
  }
</script>

<style lang="scss" scoped>

</style>