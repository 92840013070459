<template>
  <h3 class="font-sansDemi text-2xl text-black border-b border-grey-darker-200 py-3 leading-none"> 
    {{ title }}
  </h3>
</template>

<script>
  export default {
    props: [ 'title' ]
  }
</script>

<style lang="scss" scoped>

</style>