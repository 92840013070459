<template>
  <div class="w-full flex border-b border-dashed border-grey-darker-200">
    <div class="w-2/6 border-r border-grey-darker-200 py-5">
      <span class="font-sansDemi base-font-gray-200 text-xl leading-none"> {{ title }} </span>
    </div>
    <div class="w-4/6 py-5 pl-8">
      <span 
        v-if="value"
        class="base-font-gray-200 text-xl leading-none"
      > 
        {{ value }} 
      </span>
      <Loader 
        v-else
        :animation="true"
        classes="w-8 h-8"
      />
    </div>
  </div>
</template>

<script>
  import Loader from '@/components/Loader'

  export default {
    props: [ 'title', 'value' ],
    components: {
      Loader
    }
  }
</script>

<style lang="scss" scoped>

</style>