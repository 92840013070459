<template>
  <svg 
    class="inline-block" 
    :class="[ classes,  {
      'loader-animation': animation
    }]"
    viewBox="0 0 32 32" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="14.5" stroke="url(#paint0_linear_422_1208)" stroke-width="3"/>
    <defs>
      <linearGradient id="paint0_linear_422_1208" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
        <stop :stop-color="color"/>
        <stop offset="0.885948" stop-color="white" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>

</template>

<script>
  export default {
    props: {
      animation:{
        type: Boolean,
        default: false
      },
      classes: {
        type: String,
        default: 'mr-5'
      },
      color: {
        type: String,
        default: '#A4A4A4'
      }
    }
  }
</script>

<style lang="scss" scoped>
.loader-animation{
  animation: circleRight 1s linear infinite ;
}

@keyframes circleRight {
  0% {
    transform: rotate(0deg);
  }
  25%{
    transform: rotate(90deg);
  }
  50%{
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>